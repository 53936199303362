import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";

import MainLayout from "../layouts/MainLayout";
import Breadcrumb from "../components/common/breadcrumb";
// import { ProductListing } from "./sub-components/collection"
import ShopSidebar from "../components/features/sidebar/shop-sidebar";
import ProductList from "../components/features/product/list/product-list";
import { getVisibleProducts } from "../services";
// import { findIndex } from "../utils";
import Seo from "../components/common/SEO";
const Collection = (props) => {
  const { category } = props.pageContext;
  const { data } = props;
  let allProducts = data.allProducts.nodes;

  const path = props.path.trim().split("/");
  const [filters, setfilters] = useState({
    category: [],
    condition: [],
    storeLocation: [],
    brand: [],
    color: [],
    rating: false,
    value: false,
    sortBy: "",
  });
  let grid = "4cols";
  const [search, setsearch] = useState("");
  let keyword =
    typeof window !== "undefined" && window.location.search.slice(1);
  useEffect(() => {
    let urlSearch = new URLSearchParams(
      decodeURIComponent(window.location.search)
    );
    if (typeof window !== "undefined") setsearch(urlSearch.get("q"));
    return () => {};
  }, [keyword]);

  useEffect(() => {
    let queryFilters = { category: [], condition: [], storeLocation: [] };
    if (typeof window !== "undefined") {
      let urlSearch = new URLSearchParams(
        decodeURIComponent(window.location.search)
      );

      let condition = urlSearch.get("condition");

      let storeLocation = urlSearch
        .get("storeLocation")
        ?.replace(/amp;+/g, "&");
      let category = urlSearch.get("category")?.replace(/amp;+/g, "&");
      let sortBy = urlSearch.get("sortBy");

      if (condition) {
        queryFilters.condition = condition.split("_");
      }
      if (storeLocation) {
        let locations = storeLocation.split("_");
        let locationIds = data.metadata.storeLocation
          .filter((location) => locations.includes(location.name))
          .map((location) => location._id);
        queryFilters.storeLocation = locationIds;
      }
      if (category) {
        let categories = category.split("_");
        let categoryIds = data.categories.nodes
          .filter((category) => categories.includes(category.name))
          .map((category) => category._id);
        queryFilters.category = categoryIds;
      }
      if (sortBy) {
        queryFilters.sortBy = sortBy;
      }
    }

    setfilters({
      ...queryFilters,
      brand: [],
      color: [],
      rating: false,
      value: false,
    });
  }, [data]);

  const onSort = (filterVal) => {
    setfilters({ ...filters, sortBy: filterVal });
    navigate(
      `${
        typeof window !== "undefined" && window.location.pathname
      }?${getQueryString({
        ...filters,
        sortBy: filterVal,
      })}`
    );
  };
  const onPriceChange = (values) => {
    setfilters({ ...filters, value: values });
  };
  const resetFilter = () => {
    setfilters({
      category: [],
      condition: [],
      storeLocation: [],
      brand: [],
      color: [],
      rating: false,
      value: false,
      sortBy: "",
    });
  };
  const onFilter = ({ item, key }) => {
    if (key === "storeLocation") {
      let index = filters.storeLocation.findIndex((el) => el === item._id);

      let locations = filters.storeLocation;
      if (-1 === index) {
        locations.push(item._id);
      } else {
        locations.splice(index, 1);
      }
      setfilters({ ...filters, storeLocation: locations });
    } else if (key === "condition") {
      let index = filters.condition.findIndex((el) => el === item);
      let condition = filters.condition;
      if (-1 === index) {
        condition.push(item);
      } else {
        condition.splice(index, 1);
      }
      setfilters({ ...filters, condition: condition });
    } else if (key === "categories") {
      let index = filters.category.findIndex((el) => el === item._id);

      let categories = filters.category;
      if (-1 === index) {
        categories.push(item._id);
      } else {
        categories.splice(index, 1);
      }
      setfilters({ ...filters, category: categories });
    }
    navigate(
      `${
        typeof window !== "undefined" && window.location.pathname
      }?${getQueryString(filters)}`
    );
  };
  const getQueryString = (filters) => {
    let queryString = "";
    if (filters.category.length > 0) {
      let categories = data.categories.nodes
        .filter((category) => filters.category.includes(category._id))
        .map((category) => category.name.replace(/&+/g, "amp;"));
      queryString += `category=${categories.join("_")}&`;
    }
    if (filters.condition.length > 0) {
      queryString += `condition=${filters.condition.join("_")}&`;
    }
    if (filters.storeLocation.length > 0) {
      let locations = data.metadata.storeLocation
        .filter((location) => filters.storeLocation.includes(location._id))
        .map((location) => location.name.replace(/&+/g, "amp;"));
      queryString += `storeLocation=${locations.join("_")}&`;
    }

    if (filters.value) {
      queryString += `value=${filters.value}&`;
    }
    if (filters.sortBy) {
      queryString += `sortBy=${filters.sortBy}&`;
    }
    return encodeURIComponent(queryString);
  };

  if (search) {
    allProducts = allProducts.filter((p) =>
      p.title.toLowerCase().includes(search.toLowerCase())
    );
  }
  let products = getVisibleProducts(allProducts, filters);

  return (
    <MainLayout>
      <Seo
        title={
          category?.seoMetaData?.title || (category?.name ?? "all products")
        }
        category={category}
      />
      <Breadcrumb title={`${category?.name}`} parent={["collections"]} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 skeleton-body skel-shop-products">
              <ProductList
                categoryName={category?.name ?? "All Products"}
                column={grid}
                products={products}
                onSort={onSort}
                filters={filters}
              />
            </div>

            <div className="col-lg-3 order-lg-first skeleton-body skel-shop-sidebar">
              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <div className="skel-widget"></div>

              <ShopSidebar
                onPriceChange={onPriceChange}
                onFilter={onFilter}
                categories={data.categories.nodes}
                storeLocations={data.metadata.storeLocation}
                adClass="sidebar sidebar-shop"
                resetFilter={resetFilter}
                showCategories={path.includes("collections")}
                filters={filters}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <ProductListing stores={customApi.metadata.storeLocation} products={products} /> */}
    </MainLayout>
  );
};

export default Collection;

export const query = graphql`
  query getAll($ido: String) {
    metadata {
      id
      storeLocation {
        _id
        name
      }
    }
    categories: allCategories {
      nodes {
        _id
        name
        itemCount
        subCategories {
          name
        }
      }
    }
    allProducts(filter: { category: { _id: { eq: $ido } } }) {
      nodes {
        _id
        title
        description
        quantity
        condition
        price
        oldPrice
        rating
        isDefaultShippingEnabled
        isDeliverable

        isPickup
        isShippable
        seoMetaData {
          slug
        }
        storeLocation {
          _id
          name
          state
          zipCode
          address
        }
        image: thumbnail {
          childImageSharp {
            gatsbyImageData(

              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 75, height: 75, quality: 100, layout: FIXED)
          }
        }
        category {
          _id
          name
        }
        createdAt
      }
    }
  }
`;
